import { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import "./TravellersRoomButton.scss";
import classNames from "classnames";
import TravellersRoomForm from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomForm";
import {
	autoUpdate,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFocus,
	useInteractions,
} from "@floating-ui/react";
import { useFormikContext } from "formik";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TravellersRoomButton = ({
	label,
	className,
	initialValues = [],
	valueToDisplay,
	onConfirmation = () => {},
	onFocus = () => {},
	id,
	loading,
	stickyMode = false,
	validateOnMount,
	warningMessage = false,
	popperOffset = [],
	isAutoScrollActive,
	popperWidth,
}) => {
	const [open, setOpen] = useState(false);

	const { errors, validateForm, values: formValues } = useFormikContext();

	const floatingElementRef = useRef(null);

	const inputClassName = classNames(
		"travellers-room-button",
		"travellers-room-button--touched",
		className,
		{
			"travellers-room-button--error": errors?.occupancies,
			"travellers-room-button--opened": open,
		}
	);

	const handleOnOpen = useCallback(shouldOpen => {
		setOpen(shouldOpen);
		if (shouldOpen) {
			onFocus();
		}
	}, []);

	const { context, x, y, strategy, refs } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		open,
		onOpenChange: handleOnOpen,
		middleware: [offset(), shift()],
	});

	const handleValidation = useCallback(
		values => {
			setOpen(false);

			// relaunch validation to remove error color if there is no errors
			validateForm({
				...formValues,
				...values,
			});

			onConfirmation(values.occupancies);
		},
		[refs?.reference?.current, formValues]
	);

	const dismiss = useDismiss(context);
	const click = useClick(context);
	// gere le click et le focus par clavier
	const focus = useFocus(context, {
		keyboardOnly: false,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click, focus]);

	useEffect(() => {
		const handleScroll = () => {
			// do not close dropdown if errors from emirates redirect
			const shouldClosePopover = !errors?.occupancies || errors?.occupancies?.length === 0;
			if (floatingElementRef?.current && !isAutoScrollActive) {
				const { top, height } = floatingElementRef.current.getBoundingClientRect();
				const windowHeight = window.innerHeight;

				const calendarMidpoint = top + height / 2;

				const isHalfCalendarVisible =
					calendarMidpoint > 0 && calendarMidpoint < windowHeight;

				if (!isHalfCalendarVisible && open && shouldClosePopover) {
					setOpen(false);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [open, errors?.occupancies, floatingElementRef?.current, isAutoScrollActive]);

	return (
		<>
			<div
				className={inputClassName}
				ref={refs.setReference}
				id={id}
				tabIndex="0"
				{...getReferenceProps({})}
			>
				{!loading ? (
					<>
						<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
							<label className="travellers-room-button__label">{label}</label>
						</Typography>
						<div
							className="travellers-room-button__input"
							data-testid="travellers-room-button"
						>
							{valueToDisplay}
						</div>
					</>
				) : (
					<div className="travellers-room-button__loader">
						<LoaderBar height={10} width={"80%"} />
						<LoaderBar height={10} />
					</div>
				)}
			</div>
			{!loading && (
				<FloatingPortal preserveTabOrder={true}>
					{open && (
						<div
							ref={node => {
								refs.setFloating(node);
								floatingElementRef.current = node;
							}}
							className={classNames({
								"travellers-room-button__popover": true,
								"travellers-room-button__popover--sticky": stickyMode,
							})}
							{...getFloatingProps({
								style: {
									position: strategy,
									left: x + popperOffset[0] ?? "",
									top: y + popperOffset[1] ?? "",
									width: popperWidth,
								},
							})}
						>
							<div className="travellers-room-button__popover-body">
								<TravellersRoomForm
									initialValues={initialValues}
									handleValidation={handleValidation}
									validateOnMount={validateOnMount}
									warningMessage={warningMessage}
								/>
							</div>
						</div>
					)}
				</FloatingPortal>
			)}
		</>
	);
};

TravellersRoomButton.propTypes = {
	label: messagePropType,
	className: PropTypes.string,
	valueToDisplay: PropTypes.element,
	onConfirmation: PropTypes.func,
	onFocus: PropTypes.func,
	initialValues: PropTypes.array,
	id: PropTypes.string,
	stickyMode: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	loading: PropTypes.bool,
	popperOffset: PropTypes.array,
	popperWidth: PropTypes.number,
	isAutoScrollActive: PropTypes.bool,
	warningMessage: PropTypes.bool,
};

export default memo(TravellersRoomButton);
