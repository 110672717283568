import { useContext } from "react";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { format } from "date-fns";
import { FormattedDate } from "react-intl";

const DepartureDateSidePanelSelectedDate = ({ date }) => {
	const { shop } = useContext(AppGlobalsContext);
	const isArabicLang = shop.includes("ar");
	const departureDateObject = new Date(date);

	if (!date || isNaN(departureDateObject.getTime())) {
		return null;
	}

	return isArabicLang ? (
		format(departureDateObject, "yyyy/MM/dd")
	) : (
		<FormattedDate value={departureDateObject} day="2-digit" month="2-digit" year="numeric" />
	);
};

DepartureDateSidePanelSelectedDate.propTypes = {
	date: PropTypes.string,
};

export default DepartureDateSidePanelSelectedDate;
