import PropTypes from "prop-types";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import TravellersRoomFieldset from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomFieldset";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { FieldArray, Form, Formik } from "formik";
import { validateTravellersRoomFormSearch } from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE, MAX_PARTICIPANTS } from "app/constants";
import IconPlusRounded from "app/pages/.shared/static/icons/IconPlusRounded";

import "./TravellersRoomForm.scss";
import { memo, useRef } from "react";

const TravellersRoomForm = ({
	handleValidation,
	initialValues = [],
	validateOnMount,
	warningMessage = false,
}) => {
	const defaultValues = [{ adults: 2, children: 0 }];

	const formRef = useRef();

	return (
		<Formik
			enableReinitialize
			initialValues={{
				occupancies: isEmpty(initialValues) ? defaultValues : initialValues,
			}}
			validate={validateTravellersRoomFormSearch}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={handleValidation}
			initialTouched={
				validateOnMount && {
					occupancies: true,
				}
			}
			validateOnMount={validateOnMount}
		>
			{({ values, errors }) => {
				const totalParticipants = values.occupancies.reduce((total, room) => {
					return total + room.adults + room.children;
				}, 0);

				const addBedroomButtonClassName = classNames(
					"travellers-room-form__add-bedroom-button",
					{
						"travellers-room-form__add-bedroom-button--disabled":
							totalParticipants >= MAX_PARTICIPANTS,
					}
				);

				return (
					<Form className="travellers-room-form__form" ref={formRef}>
						<FieldArray
							name="occupancies"
							render={arrayHelpers => {
								return (
									<>
										<div className="travellers-room-form__popover-body">
											{values.occupancies.map((friend, index) => (
												<div
													key={index}
													className="travellers-room-form__fieldset"
												>
													<TravellersRoomFieldset
														index={index}
														showRemoveButton={index >= 1}
														onRemoveClick={() =>
															arrayHelpers.remove(index)
														}
														warningMessage={warningMessage}
													/>
												</div>
											))}
											{errors?.formGlobalErrors?.length > 0 &&
												errors.formGlobalErrors.map(error => (
													<AlertMessage
														key={error.id}
														message={<FormattedMessage id={error.id} />}
														alertType={ALERT_TYPE.ERROR}
													/>
												))}
											<div
												className="travellers-room-form__add-bedroom"
												data-testid="add-room-button"
												onClick={() => {
													if (totalParticipants >= MAX_PARTICIPANTS) {
														return;
													}

													arrayHelpers.push({
														adults: 2,
														children: 0,
													});

													// ensure the new room is visible when adding a room
													setTimeout(() => {
														if (formRef.current) {
															formRef.current.scrollTo({
																top: formRef.current.scrollHeight,
																left: 0,
																behavior: "smooth",
															});
														}
													}, 50);
												}}
											>
												<FormattedMessage
													id="sdp.search.occupancies.room.label"
													values={{
														number: values.occupancies.length + 1,
													}}
												/>
												<div className={addBedroomButtonClassName}>
													<IconPlusRounded width={15} height={15} />
													<FormattedMessage id="sdp.search.occupancies.room.add.label" />
												</div>
											</div>
										</div>
										<div className="travellers-room-form__validate-button">
											<Button
												submit
												variant="primary"
												data-testid="validate-button"
											>
												<FormattedMessage id="sdp.search.occupancies.room.validate.button.label" />
											</Button>
										</div>
									</>
								);
							}}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

TravellersRoomForm.propTypes = {
	handleValidation: PropTypes.func,
	initialValues: PropTypes.array,
	validateOnMount: PropTypes.bool,
	warningMessage: PropTypes.bool,
};

export default memo(TravellersRoomForm);
