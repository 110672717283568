import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { memo } from "react";
import { FormattedDate } from "react-intl";

const DAYS_TIMESTAMP = [
	1610364898000,
	1609846498000,
	1609932898000,
	1610019298000,
	1610105698000,
	1610192098000,
	1610278498000,
];

const WeekDays = () => {
	return (
		<ul className="week-days">
			{DAYS_TIMESTAMP.map(timestamp => (
				<li className="week-days__item" key={timestamp}>
					<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR} component="span">
						<FormattedDate value={timestamp} weekday="short" />
					</Typography>
				</li>
			))}
		</ul>
	);
};

export default memo(WeekDays);
